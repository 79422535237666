

.battlemech {
    width: 100%;

    background: rgba(31, 28, 37, 0.8);
    &__content {
        position: relative;
    }
    &__img {
        img{
            width: 100%;
            height: 100%;
        }
   }
   &__text {
        width: 50%;
        height: 100%;
        background: rgba(31, 28, 37, 0.8);
        position: absolute;
        top: 0%;
        right: 0%;
   }
        p {
            margin: 20% 0px 0px 5%;
            max-width: 529px;
            font-family: 'KoHo';
            font-weight: 500;
            font-size: 26px;
            line-height: 34px;
            color: #FFFFFF;  
            }
  
    &__title {
        margin: 37px 0px 50px 0px;
    }

   
    
}

@media (max-width: 550px) {
        .battlemech {
            &__content {
                display: flex;
                flex-direction: column;
            }
            &__text {
                width: 100%;
                position: static;
                margin: 0 auto;
                background: none;
                p {
                margin: 13px 27px;
                font-size: 14px;
                line-height: 18px;
                }
            }
        }
}