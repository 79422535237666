@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@500;600;700&display=swap');

@font-face {
	font-family: Industry;
	font-weight: 600;
	src: url('../fonts/Industry-Demi.ttf');
}

@font-face {
	font-family: Industry;
	font-weight: 700;
	src: url('../fonts/Industry-Bold.ttf');
}


@import './components/variables';


* {
	padding: 0;
	margin: 0;
	border: 0;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	color:#FFFFFF;
	background-color: $dark;
}

input,
button,
textarea {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}


.title-gold {
	text-transform: uppercase;
	font-family: 'Industry';
	font-style: normal;
	font-weight: 600;
	font-size: 60px;
	line-height: 72px;
	text-align: center;
	color: #FFFFFF;
	text-shadow: 0px 0px 8px rgba(188, 146, 64, 0.76);
}

.title-purpl {
	text-transform: uppercase;
	font-family: 'Industry';
	font-weight: 600;
	font-size: 50px;
	line-height: 60px;
	text-align: center;
	color: #AEA0FF;
}





.app {
	margin: 0 auto;
	max-width: 1920px;
	overflow-x: hidden;
}









@media (max-width: 1250px) {
	.title-gold {
		font-size: 50px;
		line-height: 60px;
	}
	
	.title-purpl {
		font-size: 40px;

	}
	
}



@media (max-width: 750px) {
    .title-gold {
		font-size: 40px;
		line-height: 50px;
	}
	
	.title-purpl {
		font-size: 35px;
		line-height: 50px;
	}
	
}



@media (max-width: 550px) {
    .title-gold {
		font-size: 25px;
		line-height: 30px;
	}
	
	.title-purpl {
		font-size: 20px;
		line-height: 24px;
	}
	
}




