
.origin {
    padding: 0px 89px  0px 97px;
    width: 100%;
    &__content {
        background: url('../../../public/images/origin/borderLeft.png') 63px 0px /  auto   no-repeat;
        .itemOne {
            background: url('../../../public/images/origin/itemBgBig.png') 0 0  / cover no-repeat;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            margin-left: 62px;

            &__img {
                width: 50%;
                margin-top: 52px;
              img {
                width: 100%;
                height: auto;
                object-fit: cover;
              } 
            }
            &__text {
                width: 50%;
                margin: 0 44px;
                max-width: 681px;;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
            font-family: 'KoHo';
            font-weight: 500;
            font-size: 26px;
            line-height: 34px;
            text-align: center;
                }
            }
        }

        .itemTwo {
            background: url('../../../public/images/origin/itemTwoImg.png') 0 0 / auto no-repeat;
            min-height: 630px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 65px;
            &__lable {
                padding: 72px 19px 18px 132px;
                background: url('../../../public/images/origin/itemTwoBg.png') 0 0 / auto no-repeat;
                &-text {
                    max-width:  681px;
                    min-height: 214px;
                    p {
                    font-family: 'KoHo';
                    font-weight: 600;
                    font-size: 26px;
                    line-height: 34px;
                    text-align: center;
                    }
                }
            }
            
        }
        .itemThree {
            background: url('../../../public/images/origin/itemBgFour.png') 0px center / auto no-repeat;
            display: flex;
            justify-content: space-between;
            margin-left:63px;
            margin-bottom: 65px;
            &__img {
                width: 42.7%;
                img {                
                    max-width: 100%;
                    height: auto;
                }
            }
            &__text {
                width: 57.3%;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                max-width: 681px;
                font-family: 'KoHo';
                font-weight: 500;
                font-size: 26px;
                line-height: 34px;
                text-align: center;
                }
            }
        }

        .itemFour {
            background: url('../../../public/images/origin/itemFourImg.png') 0 0 / auto no-repeat;
            min-height: 630px;
            display: flex;
            justify-content: end;
            align-items: end;
            margin-bottom: 96px;

            &__lable {
                background: url('../../../public/images/origin/itemBgSmall.png') 0 0 / auto no-repeat;
                padding: 86px 76px 38px 165px;
                &-text {
                    max-width:  681px;
                    p {
                    font-family: 'KoHo';
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 34px;
                    text-align: center;
                    }
                }
            }
            
        }
    }

    &__title  {
        margin-bottom: 33.27px;
    }
}