
.planets {
    background: url('../../../public/images/planets/bg-left.png')  -20% top    / auto no-repeat;
    padding-bottom: 119px;

    &__title {
   
    min-height: 79.54px;
    padding: 17px 0px;
    margin-bottom: 76px;
    }

    &__body {
        background: url('../../../public/images/planets/space.png') center /  cover no-repeat;
        display: flex;
        min-height:  747px;
        width: 100%;
    }
    &__img {
        position: relative;
        width: 50%;
        img {
            transition: all .3s linear;
        }
     

        &-red {
            position: absolute;
            top: 41px;
            right: 147px;
            z-index: 10;
            width: 570px;

            

        
            &.active {
                width: 400px;
                cursor: pointer;
                z-index: 0;
               
            }
        }

        &-green {
            position: absolute;
            bottom: 59px;
            right: 0px;
            z-index: 0;          
            width: 450px;

            &.active {
                width:  382px;
                cursor: pointer;
                z-index: 0;
               
            }
        }
    }
    &__text {
        width: 50%;
        display: flex;
        align-items: center;
        p {
            margin-left: 147px;
            font-family: 'KoHo';
            max-width: 603px;
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 34px;
            color: #FFFFFF;
        }
    }
}


@keyframes red {
    50% {
        transform: scale(0.5);
    }

    100% {

    }

}