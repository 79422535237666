.little {
    text-align: center;
    overflow: hidden;
    &__row {
        display: flex;
        align-items: center;
        padding: 20px 0px 90px 0px;
     

      
    }

    &__item {
        width: 33.333%;
        transition: all 0.3s ease;
        &:hover  {
            transform: scale(1.15);
     
        }
        img {
            max-width: 100%;
            height: auto;
        }


        &-left {
            &:hover {
               transform: scale(1.15);
            }
        }
        
    }



}