

.pilots {
    background: url('../../../public/images/pilots/pilotsBg.png') center top / 100% auto no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 79px 144px 149px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__one {
        margin-top: 25px;
    }
    &__title {
        margin-top: 65px;
    }


    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'KoHo';
        max-width: 845px;
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        color: #FFFFFF;
        z-index: 10;
        margin: 0 -120px;
    }

}