@import './variables';



.preview {
   background: url('../../../public/images/preview/previewBg.png') 65% 0 / cover  no-repeat;
   display: flex;
   height: calc(100vh - 100px);
   align-items: flex-end;
   justify-content: center;

    &__content {
        max-width: 1031px;
        margin-bottom: 50px;
    }

    &__img {
    img {
        width: 100%;
        height: auto;
    }
    }


    &__title {
        margin-top: -50px;
        font-family: 'Industry';
        font-weight: 700;
        font-size: 55px;
        line-height: 66px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.65);
    }
}



@media (max-width: 1250px) {

    .preview  {
        &__title {
            font-size: 40px;
           
        }
    }
    
}


@media (max-width: 750px) {
   
    
}



@media (max-width: 550px) {
    .preview {
        height: calc(100vh - 60px);
        &__title {
            margin: 0px;
            font-size: 20px;
            line-height: 30px;
           
        }

        &__content {
            max-width: 300px;
            margin-bottom: 5px;
        }
    }
    
}