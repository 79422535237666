.why {
    background: url('../../../public/images/why/bg.png') center  / cover no-repeat;
    padding: 0 15px;
    &__container {
        
        max-width: 1520px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

    
    }
    &__title  {
        margin: 60px 0px 63px 0px;
    }

    &__content {
        margin-bottom: 104px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-right {
            align-self: flex-end;
            text-align: right;
            .why__reason {
            flex-direction: row-reverse;
            p {
                max-width: 831px;
            }
    
            .lable {
                margin: 0 0 0 24px;
                max-width: 266px;
               
            }
            }
        }
    }

    .lable {
        min-width: 307px;
        height: 5px;
        background-color: #695BBF;
        margin-right: 24px;
    }

  

    &__reason {
        display: flex;
        align-items: center;
        padding-bottom: 47px;

         p  {
            font-family: 'KoHo';
            font-weight: 600;
            font-size: 26px;
            line-height: 34px;
            color: #DEE2EF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            max-width: 775px;
           }
    }

}


@media (max-width: 1250px) {

    .why {
        .lable {
            min-width: 200px;
        
        }
    

    
    &__reason {
        padding-bottom: 30px;

         p  {
                font-size: 22px;
           }
    }
   
}

}


@media (max-width: 750px) {
        .why  {
          
        }
}



@media (max-width: 550px) {
    .why {
        &__title  {
            margin: 55px 0px 16px 0px;
        }
    }
}

