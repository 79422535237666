


.how-earn {
    background: url('../../../public/images/earn/earnBg.png') top center / 100% no-repeat;
    padding: 0 15px;
    &__title {
    padding-top: 20px;
    }

    &__content {
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    &__gallery {
        max-width: 763px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 66px;
        flex: 0 0 52.5%;

    &-row {
        display: flex;
        .hero {
            img {
                width: 100%;
                height: auto;
            }
        }
      }
  
    }

    &__info {
        flex: 0 0 47.5%;
        margin-top: 133px;
        max-width: 590px;
        margin-left: 86px;
        font-family: 'KoHo';
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
    }

    .methods-list {
      padding-left: 40px;
      margin-right: 40px;
    }


    .hero {
        margin: 0 19.56px 19.56px 0;
    }
}