
.video {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../../../public/images/video/bg.png')  5% 0 / auto 100% no-repeat,
    url('../../../public/images/video/bg.png')  95% 0 / auto  100% no-repeat;
 

    &__content {
        width: 60%;
        height: 60%;

        img {
            width: 100%;
            height: 100%;
        }
    }

   
    
}

@media (max-width: 750px) {
    .video {
        background: url('../../../public/images/video/bg.png')  0 0 / auto 100% no-repeat,
    url('../../../public/images/video/bg.png') 100% 0 / auto  100% no-repeat;;
        &__content {
            width: 90%;
        }
    }
}
   



