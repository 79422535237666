
.factions {
    margin-bottom: 192px;
    &__content  {
        margin: 0px 89px  0px 97px;
    }
    &__title  {
       padding: 53px 0px;
       background: url('../../../public/images/factions/titleBg.png') top right / 75%  auto no-repeat;
       margin-bottom: 44px;
    }

  
    .more {
        background: none;
            img {
            &:hover {
              
                animation: move 0.5s infinite 0.1s;
            }
        }

            @keyframes move {
                0% {
                    transform: translateY(10px);
                } 50% {
                    transform: translateY(0px);
                }
                100% {
                    transform: translateY(10px);
                } 
                }
    }

    .faction {
        display: flex;
        margin-bottom: 101px;
        text-align: left;
        &-right {
            flex-direction: row-reverse;
            text-align: right;
        }

        &__img {
            width: 50%;
            img {
                width: 100%;
                height:auto;
            }
        }
    

    

        &__info {
            margin: 90px 33px 56.33px 63px;
            display: flex;
            flex-direction: column;

        }
        
        &__title {          
            font-family: 'KoHo';
            font-weight: 700;
            font-size: 30px;
            line-height: 39px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 55px;
        }

        &__desc {
            max-width: 742px;
            font-family: 'KoHo';
            font-weight: 500;
            font-size: 26px;
            line-height: 34px;
            margin-bottom: 22px;
        }

        &__btn {
            background: none ;
            align-self: center;
        }
    }

    
}